import {
  Button, Col, DatePicker, Form, Row, Select, Spin, Table, Tag
} from 'antd';
import PropTypes from "prop-types";
import { stringify } from 'query-string';
import { Component } from 'react';
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom';

import moment from 'moment';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './List.css';

const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

class Gatherings extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedGatheringType: '',
      gatheringDate: '',
      gatherings: [],
      gatheringTypes: [],
      loading: false,
      loadingTypes: false,
    };

    this.columns = (gatherings) => {
      return [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          sorter: (a, b) => a.name.localeCompare(b.name),
          render: (text, record) => (
            <span>
              <NavLink
                to={`/gatherings/${record._id}/edit`}
              >
                {gatherings[record.name]}
              </NavLink>
            </span>
          ),
        },
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
          render: type => <span>{type}</span>
        },
        {
          title: 'Scope',
          dataIndex: 'scope',
          key: 'scope',
          render: scope => <span>{scope}</span>
        },
        {
          title: 'Gathering For Locale',
          dataIndex: 'gatheringForLocale',
          key: 'gatheringForLocale',
          render: gatheringForLocale => <span>{gatheringForLocale?.name}</span>
        },
        {
          title: 'Date/Time',
          dataIndex: 'startDateTime',
          key: 'startDateTime',
          sortDirections: ['ascend'],
          sorter: (a, b) => moment(a.startDateTime).unix() - moment(b.startDateTime).unix(),
          render: startDateTime => (
            <span>{`${moment(startDateTime).format("MMM.DD, YYYY (ddd) h:mmA")}`}</span>
          ),
        },
        {
          title: 'Active',
          dataIndex: 'active',
          key: 'active',
          sortDirections: ['ascend', 'descend'],
          sorter: (a, b) => {
            let aY = a.active ? "Y" : "N";
            let bY = b.active ? "Y" : "N";
            return aY.localeCompare(bY);
          },
          render: active => <div><Tag color={active ? "green": "blue"}>{active ? "Y" : "N"}</Tag></div>,
        },
        {
          title: 'Is Auto-Start?',
          dataIndex: 'autoStart',
          key: 'autoStart',
          render: autoStart => <div><Tag color={autoStart ? "green": "blue"}>{autoStart ? "Y" : "N"}</Tag></div>
        },
        {
          title: 'Channels',
          key: 'channels',
          render: (text, record) => (
            <span>
              {
                record.channels.map(item => {
                  return <Tag key={item} color="blue">{item}</Tag>
                })
              }
            </span>
          ),
        },
        {
          title: 'Created By',
          key: 'createdBy',
          render: (text, record) => (
            <span>
              {record.createdBy ? record.createdBy.name : ''}
            </span>
          ),
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <span>
              <NavLink
                to={`/gatherings/${record._id}/attendees`}
              >
                <Button type="link" icon="team" />
              </NavLink>
              <NavLink
                to={`/gatherings/${record._id}/edit_weeknum`}
              >
                <Button type="link" icon="calendar" />
              </NavLink>
              <NavLink
                to={`/gatherings/${record._id}/link_accesses`}
              >
                <Button type="link" icon="link" />
              </NavLink>
              <NavLink
                to={`/gatherings/${record._id}/zoom_links`}
              >
                <Button type="link" icon="link" />
              </NavLink>
              <NavLink
                to={`/gatherings/${record._id}/search`}
              >
                <Button type="link" icon="search" />
              </NavLink>
              <NavLink
                to={`/gatherings/${record._id}/history`}
              >
                <Button type="link" icon="audit" />
              </NavLink>
            </span>
          ),
        },
      ];
    }
  }

  componentDidMount() {
    this.setState({ loadingTypes: true });
    this.callApi(`/ams/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data, loadingTypes: false }))
      .catch(err => console.log(err));

    let query = { limit: 200 };
    this.setState({ loading: true });
    this.callApi(`/ams/gatherings?${stringify(query)}`)
      .then(res => this.setState({ gatherings: res.gatherings, loading: false }))
      .catch(err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.setState({ loadingTypes: true });
      this.callApi(`/ams/gathering_types`)
        .then(res => this.setState({ gatheringTypes: res.data, loadingTypes: false }))
        .catch(err => console.log(err));

      let query = { limit: 200 };
      this.setState({ loading: true });
      this.callApi(`/ams/gatherings?${stringify(query)}`)
        .then(res => this.setState({ gatherings: res.gatherings, loading: false }))
        .catch(err => console.log(err));
    }
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleFilter = async () => {
    const query = {
      name: this.state.selectedGatheringType,
      gatheringDate: this.state.gatheringDate,
    };
    this.callApi(`/ams/gatherings?${stringify(query)}`)
      .then(res => this.setState({ gatherings: res.gatherings, loading: false }))
      .catch(err => console.log(err));
  };


  render() {
    const { selectedGatheringType, gatheringDate, gatherings, gatheringTypes, loading } = this.state;
    let modResult = [];
    if (gatherings && gatherings.length > 0) {
      let i = 0;
      gatherings.forEach(item => {
        modResult.push({ ...item, key: i++ });
      });
    }

    const gatheringEntries = [];
    gatheringTypes.forEach(item => {
      gatheringEntries.push([item.code, item.name]);
    })
    const gatheringTypesMap = {};
    gatheringTypes.forEach(item => {
      gatheringTypesMap[item.code] = item.name;
    })
    let columns = this.columns(gatheringTypesMap);

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Gatherings Page</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item label="Gathering:">
                  <Select
                    allowClear
                    placeholder="Select the gathering type"
                    onChange={value => this.setState({ selectedGatheringType: value })}
                    value={selectedGatheringType}
                  >
                    {gatheringEntries.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Date:">
                  <DatePicker
                    showToday
                    format="YYYY-MM-DD"
                    onChange={(date, dateString) => this.setState({ gatheringDate: dateString})}
                    defaultValue={gatheringDate ? moment(gatheringDate) : null}
                  />
                  <Button
                    type="primary"
                    style={{marginLeft: '10px'}}
                    onClick={this.handleFilter}
                  > Filter</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={16}>
              {(gatherings.length === 0) ?
                <div style={{textAlign: 'center'}}>
                  <h3>{`Sorry, no gatherings found.`}</h3>
                </div>
              :
                <div>
                  <h3>{`Here's are the list of registered gatherings:`}</h3>
                    <Table
                      columns={columns}
                      dataSource={modResult}
                      pagination= {{
                        hideOnSinglePage: true,
                        showSizeChanger: true,
                        defaultPageSize: 25,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        pageSizeOptions: ['25', '50', '100']
                      }}
                    />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(Gatherings);